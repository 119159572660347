import MenuList from '@/views/Index/comonents/menu.vue'
import NoContent from '@/components/NoContent/NoContent.vue'
import * as Api from '@/api/common/common'
import { formatMoment, translateTag } from '@/utils/utils'

import * as ENUM from '@/enums/API'

export default {
  name: 'Search',
  data () {
    return {
      searchData: null,
      noContentData: {
        pic: 'course_pic',
        tip: '暂无搜索结果，试试搜索其他关键词~'
      },
      ENUM
    }
  },
  components: { MenuList, NoContent },
  mounted () {
    this.onSearch()
  },
  computed: {
    keyWords () {
      return this.$route.query.keyWords
    },
  },
  methods: {
    onSearch () {
      const params = {
        keyword: this.keyWords
      }
      Api.search(params, res => {

        this.searchData = this.formatDataPath(res)
      })
    },
    formatDataPath(data){
      data.forEach(section=>{
        switch (section.view){
          case 'course_free_course':
            section.content.forEach(item=>{
              item.path =`/pastCourseDetail/${item.id}`
            })
            break
          case 'spu':
            section.content.forEach(item=>{
              item.path =`/course/${item.id}/${item.default_sku_id}`
            })
            break
          case 'community_group_training_camp':
            section.content.forEach(item=>{
              item.path =`/activityDetail/${item.id}`
            })
            break
          case 'community_group_public_course':
            section.content.forEach(item=>{
              item.path =`/activityDetail/${item.id}`
            })
            break
          case 'material':
            section.content.forEach(item=>{
              item.path =`/material/${item.id}`
            })
            break
          default:
            section.content.forEach(item=>{
              item.path =`/course/${item.id}/${item.default_sku_id}`
            })
            break
        }
      })
      return data
    },
    formatMoment, translateTag
  },
  watch: {
    $route: {
      handler: function () {
        this.onSearch()
      },
      deep: true
    }
  }
}
