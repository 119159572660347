<template>
  <div class="no-content-box">
    <div class="no-content">
      <div></div>
      <div class="img">
        <img :src="img" alt="">
      </div>
      <div class="tip">{{data.tip}}</div>
    </div>
  </div>
</template>

<script>
  import schedule_pic from './img/schedule_pic.png'
  import coupon_pic from './img/coupon_pic.png'
  import evaluation_pic from './img/evaluation_pic.png'
  import course_pic from './img/course_pic.png'
  import order_pic from './img/order_pic.png'

  export default {
    name: 'noContent',
    props: {
      data: {
        type: Object,
        default: () => {
          {
            pic:''
            tip:''
          }
        }
      }
    },
    computed: {
      img: function () {
        let pic = this.data.pic
        switch (pic) {
          case 'schedule_pic':
            return schedule_pic
          case 'coupon_pic':
            return coupon_pic
          case 'evaluation_pic':
            return evaluation_pic
          case 'course_pic':
            return course_pic
          case 'order_pic':
            return order_pic
          default:
            return schedule_pic
        }
      }
    },
    data () {
      return {}
    }
  }
</script>

<style lang="less" scoped>
  /*@import '../../assets/style/lib-base';*/
  .no-content-box {
    width: 100%;
    height: 373px;
  }
  .no-content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 28% 50% 30%;

    img {
      width: 214px;
      height: 139px;
    }

    .tip {
      font-size: 12px;
      color: #999;
    }
  }
</style>
