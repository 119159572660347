<template>
  <div class="page-search pageContent">
    <menu-list class="navs"></menu-list>
    <div class="search-content" v-if="searchData&& searchData.length>0">
      <template v-for="(section) in searchData">
        <div class="section" :key="section.title" >
          <div class="section-title" >
            {{ section.title }}
          </div>
          <div class="section-content">
            <div class="item" v-for="(item,index) in section.content" :key="index">
              <router-link :to="{path:`${item.path}`}">
                <div class="left">
                  <div class="img">
                    <img :src="item.cover_image_url" alt="">
                  </div>
                  <div class="course-desc">
                    <div class="course-title">
                      <span v-if="section.view !=='spu'">{{ translateTag(item.category_id).abbr }}</span>
                      {{ item.title || item.name }}
                    </div>
                    <div class="course-time" >
                      <!--                    <span v-if="item.class_type === ENUM.CLASS_TYPE.PRIVATE">即时上课</span>-->
                      <!--                    <template v-else-if="item.course_mode === ENUM.COURSE_MODEL.VIDEO">-->
                      <!--                      <span >有效期{{item.valid_days}}天</span>-->
                      <!--                    </template>-->
                      <!--                    <template v-else-if="item.course_mode === ENUM.COURSE_MODEL.LIVE">-->
                      <!--                      <span >开课时间 {{formatMoment(item.started_at, 'YYYY-MM-DD')}}</span>-->
                      <!--                    </template>-->
                      <span v-if="section.view !== 'material'&& section.view !== 'spu'">开课时间 {{ formatMoment(item.started_at, 'YYYY-MM-DD') }}</span>
                    </div>
                    <div class="course-price" v-if="section.view !=='spu'  ">
                      免费  <span style="font-size:12px; color: #999 ">{{item.enroll_count}}人已报名</span>
                    </div>
                    <div class="course-price" v-else>
                      ￥{{ item.price }} <span style="font-size:12px; color: #999 " v-if="item.is_multiple_price">起</span>
                    </div>
                  </div>
                </div>
                <div class="course-button">查看详情</div>
              </router-link>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="search-no-content" v-else>
      <no-content
              :data="noContentData"
      ></no-content>
    </div>

  </div>
</template>

<script>
  import SearchJs from '@/views/Search/Search.js'

  export default SearchJs
</script>

<style scoped lang="less">
  .page-search {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    min-height: 600px;

    & > .navs {
      // position: fixed;
      // top: 130px;
      width: 250px;
      height: 544px;
      background: #ffffff;
      border-radius: 20px;
    }

    & > .search-content {
      width: 920px;
      .section{
        margin-bottom: 50px;
      }
      .section-title {
        font-weight: bold;
        padding-left: 20px;
        margin-bottom: 10px;
        font-size: 20px;
        color: #333;
        text-align: left;
      }

      .section-content {
        box-sizing: border-box;
        padding: 30px;
        width: 100%;
        background: #fff;
        border-radius: 20px;

        & > .item {
          & > a {
            width: 100%;
            height: 138px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left {
              display: flex;
              align-items: center;

              & > .img {
                width: 176px;
                height: 99px;
                margin-right: 20px;
                border-radius: 3px;
                overflow: hidden;

                & > img {
                  width: 100%;
                }
              }

              & > .course-desc {
                text-align: left;

                & > .course-title {
                  display: flex;
                  align-items: center;

                  & > span {
                    background: #667482;
                    color: #fff;
                    padding: 2px 4px;
                    margin-right: 8px;
                    font-size: @fontSizeCon;
                  }

                  font-size: @fontSizeTitle;
                  color: #333;
                  font-weight: bold;
                }


                & > .course-time {
                  font-size: @fontSizeSubTitle;
                  color: #5A5C63;
                  margin: 6px 0 30px;

                  .course-tag {
                    border-radius: 2px;
                    padding: 0 2px;
                    background: #DEF7E6;
                    color: #20A84C;
                  }
                }

                & > .course-price {
                  color: @papaRed;
                  font-size: @fontSizeTitle;
                  font-weight: 600;
                }
              }
            }


            & > .course-button {
              width: 136px;
              height: 60px;
              background: #FFFFFF;
              border: 1px solid #29D087;
              border-radius: 30px;
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #29D087;
              line-height: 60px;
            }
          }
        }

      }
    }
    &> .search-no-content{
      width: 100%;
      height: 544px;
      border-radius: 20px;
      background: #fff;
    }
  }


</style>
